import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationError, Form, FormSelect } from "@schneiderpp/utils-forms";
import { ds } from "../../DataSource";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";

interface ChangeFormFields {
    StatusId: string;
}

interface ChangeState {
    providedSupplyId: number | undefined;
    backToClient: boolean;
    fields: ChangeFormFields;
    fieldErrors: FormValidationError<ChangeFormFields>[];
    datasource: {
        SupplyStatusList: ParentStateDatasource<typeof Endpoint.Supply.GetSupplyStatusList>;
        SupplyDetails: ParentStateDatasource<typeof Endpoint.Supply.GetSupplyDetails>;
        SupplyChangeStatus: ParentStateDatasource<typeof Endpoint.Supply.PostSupplyChangeStatus>;
    };
}

class ChangeSupplyStatus extends BaseComponent<RouteComponentProps, ChangeState> {
    state: ChangeState = {
        providedSupplyId: undefined,
        backToClient: false,
        fields: {
            StatusId: ""
        },
        fieldErrors: [],
        datasource: {
            SupplyStatusList: DataSourceStateIdle,
            SupplyDetails: DataSourceStateIdle,
            SupplyChangeStatus: DataSourceStateIdle
        }
    };

    private dsSupplyStatusList = ds(Endpoint.Supply.GetSupplyStatusList, this, "SupplyStatusList", () => this.context);
    private dsDetails = ds(Endpoint.Supply.GetSupplyDetails, this, "SupplyDetails", () => this.context);
    private dsSupplyChangeStatus = ds(Endpoint.Supply.PostSupplyChangeStatus, this, "SupplyChangeStatus", () => this.context);

    private form = new Form<ChangeFormFields>(
        this,
        async () => [],
        code => code
    );

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const backToClient = urlParams.get("BackToClient");
        if (!!backToClient && backToClient === "true") {
            this.setState({ backToClient: true });
        }
        const SupplyId = urlParams.get("SupplyId");
        if (!SupplyId) {
            return;
        }
        const SupplyIdInt = parseInt(SupplyId);
        if (!Number.isInteger(SupplyIdInt)) {
            return;
        }
        this.setState({ providedSupplyId: SupplyIdInt }, () => {
            this.getSupplyStatusList();
            this.getDetails();
        });
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link
                        to={{
                            pathname: ROUTER_HOME.Supplies.Details,
                            search: `SupplyId=${this.state.providedSupplyId}${this.state.backToClient ? "&BackToClient=true" : ""}`
                        }}
                        className="button clear no-left-padding"
                    >
                        <span className="button__icon">arrow_back_ios</span>
                        wróć do szczegółów zatowarowania
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Zmień Status Zatowarowania</div>
                    <div className="page-form">
                        <FormSelect
                            config={this.form.getFieldConfig("StatusId")}
                            label="Status"
                            options={this.supplyStatusList.map(c => ({ value: c.SupplyStatusId.toString(), label: c.Name }))}
                        />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            Zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get supplyStatusList() {
        const dsSupplyStatusListData = this.dsSupplyStatusList.dataSourceStorage;
        if (dsSupplyStatusListData.state === "completed") {
            return dsSupplyStatusListData.response.supplyStatuses;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsSupplyChangeStatus.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsSupplyChangeStatus.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsSupplyChangeStatus.error === "string" ? this.dsSupplyChangeStatus.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsSupplyChangeStatus.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (
            this.dsSupplyStatusList.state === "pending" ||
            this.dsSupplyStatusList.state === "idle" ||
            this.dsDetails.state === "pending" ||
            this.dsDetails.state === "idle"
        ) {
            return {
                show: true,
                title: "Ładowanie.."
            };
        }
        if (this.dsSupplyStatusList.state === "error") {
            return {
                show: true,
                title: "nie wczytano listy",
                description: typeof this.dsSupplyStatusList.error === "string" ? this.dsSupplyStatusList.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getSupplyStatusList()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getDetails()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getSupplyStatusList() {
        await this.dsSupplyStatusList.request({});
    }

    private async getDetails() {
        if (!this.state.providedSupplyId) {
            return;
        }
        await this.dsDetails.request({ params: { SupplyId: this.state.providedSupplyId } });
        const dsDetails = this.dsDetails.dataSourceStorage;
        if (dsDetails.state === "completed") {
            this.form.setFieldValue("StatusId", () => dsDetails.response.Status.SupplyStatusId.toString());
        }
    }

    private async submit() {
        if (!this.state.providedSupplyId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsSupplyChangeStatus.request({
                data: {
                    SupplyStatusId: parseInt(this.state.fields.StatusId),
                    SupplyId: this.state.providedSupplyId
                }
            });
            if (this.dsSupplyChangeStatus.dataSourceStorage.state === "completed") {
                this.props.history.push(
                    this.state.backToClient
                        ? {
                              pathname: ROUTER_HOME.Supplies.Details,
                              search: `SupplyId=${this.state.providedSupplyId}${this.state.backToClient ? "&BackToClient=true" : ""}`
                          }
                        : { pathname: ROUTER_HOME.Supplies.Details, search: `SupplyId=${this.state.providedSupplyId}` }
                );
            }
        }
    }
}

export default withRouter(ChangeSupplyStatus);
