import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField, FormSelect } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface EditFormFields {
    Name: string;
    SalesmanId: string;
    Company: string;
}

interface EditState {
    providedClientId: number | undefined;
    fields: EditFormFields;
    fieldErrors: FormValidationError<EditFormFields>[];
    datasource: {
        Edit: ParentStateDatasource<typeof Endpoint.Client.PostClientEdit>;
        SalesmanList: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanList>;
        Details: ParentStateDatasource<typeof Endpoint.Client.GetClientDetails>;
    };
}

const editFormValidate: FormValidationFunction<EditFormFields> = async (fields) => {
    const errors: Array<FormValidationError<EditFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }

    if (fields.Company.length < 3) {
        errors.push({ fieldName: "Company", code: "CompanyTooShort" });
    }
    return errors;
};

class Edit extends BaseComponent<RouteComponentProps, EditState> {
    state: EditState = {
        providedClientId: undefined,
        fields: {
            Name: "",
            SalesmanId: "",
            Company: ""
        },
        fieldErrors: [],
        datasource: {
            Edit: DataSourceStateIdle,
            SalesmanList: DataSourceStateIdle,
            Details: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Client.GetClientDetails, this, "Details", () => this.context);

    private dsSalesmanList = ds(Endpoint.Salesman.GetSalesmanList, this, "SalesmanList", () => this.context);

    private dsEdit = ds(Endpoint.Client.PostClientEdit, this, "Edit", () => this.context);

    private form = new Form<EditFormFields>(this, editFormValidate, (code) => {
        switch (code) {
            case "NameTooShort":
                return "Nazwa za krótka";
            case "CompanyTooShort":
                return "Nazwa Firmy za krótka";
            default:
                return code;
        }
    });

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const ClientId = urlParams.get("ClientId");
        if (!ClientId) {
            return;
        }
        const ClientIdInt = parseInt(ClientId);
        if (!Number.isInteger(ClientIdInt)) {
            return;
        }
        this.setState({ providedClientId: ClientIdInt }, () => {
            this.getDetails();
            this.getSalesmanList();
        });
    }

    render() {
        if (!this.state.providedClientId) {
            return (
                <div className="page">
                    <div className="page__haeder">Nie odnaleziono ClientId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link
                            to={{ pathname: ROUTER_HOME.Client.Detials.About, search: `ClientId=${this.state.providedClientId}` }}
                            className="button clear no-left-padding"
                        >
                            <span className="button__icon">arrow_back_ios</span> wróć do detali Klienta
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">Edytuj Klienta</div>
                        <div className="page-form">
                            <FormTextField config={this.form.getFieldConfig("Name")} label="Imię i nazwisko" />
                            <FormTextField config={this.form.getFieldConfig("Company")} label="Nazwa Firmy" />
                            <FormSelect
                                config={this.form.getFieldConfig("SalesmanId")}
                                label="Handlowiec"
                                options={this.salesmanList.map((c) => ({ value: c.SalesmanId.toString(), label: c.Name }))}
                            />
                            <button className="button align-self-end margin-top-10" onClick={()=> this.submit()}>zatwierdź</button>
                        </div>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (
            this.dsDetails.state === "pending" ||
            this.dsDetails.state === "idle" ||
            this.dsSalesmanList.state === "pending" ||
            this.dsSalesmanList.state === "idle" ||
            this.dsEdit.state === "pending"
        ) {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getDetails()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsSalesmanList.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsSalesmanList.error === "string" ? this.dsSalesmanList.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getSalesmanList()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsEdit.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsEdit.error === "string" ? this.dsEdit.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsEdit.resetState()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    get salesmanList() {
        const dsSalesmanListData = this.dsSalesmanList.dataSourceStorage;
        if (dsSalesmanListData.state === "completed") {
            return dsSalesmanListData.response.salesmans;
        }
        return [];
    }

    private async getSalesmanList() {
        await this.dsSalesmanList.request({});
        const dsSalesmanListData = this.dsSalesmanList.dataSourceStorage;
        if (dsSalesmanListData.state === "completed" && dsSalesmanListData.response.salesmans.length > 0) {
            this.form.setFieldValue("SalesmanId", () => dsSalesmanListData.response.salesmans[0].SalesmanId.toString());
        }
    }

    private async getDetails() {
        if (!this.state.providedClientId) {
            return;
        }
        await this.dsDetails.request({
            params: { ClientId: this.state.providedClientId }
        });
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            const response = dsDetailsData.response;
            this.setState(() => ({
                fields: {
                    Name: response.Name,
                    Company: response.Company,
                    SalesmanId: response.Salesman.SalesmanId.toString()
                }
            }));
        }
    }

    private async submit() {
        if (!this.state.providedClientId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsEdit.request({
                data: {
                    Name: this.state.fields.Name,
                    Company: this.state.fields.Company,
                    ClientId: this.state.providedClientId,
                    SalesmanId: parseInt(this.state.fields.SalesmanId)
                }
            });

            if (this.dsEdit.dataSourceStorage.state === "completed") {
                this.props.history.push({ pathname: ROUTER_HOME.Client.Detials.About, search: `ClientId=${this.state.providedClientId}` });
            }
        }
    }
}

export default withRouter(Edit);
