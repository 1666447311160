import React from "react";
import { ds } from "../../DataSource";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationError, FormValidationFunction, Form, FormTextField, FormSelect } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";

interface AddFormFields {
    Name: string;
    Company: string;
    Email: string;
    SalesmanId: string;
}

interface AddState {
    fields: AddFormFields;
    fieldErrors: FormValidationError<AddFormFields>[];
    datasource: {
        ClientAdd: ParentStateDatasource<typeof Endpoint.Client.PostClientAdd>;
        SalesmanList: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanList>;
    };
}

const addFormValidate: FormValidationFunction<AddFormFields> = async (fields) => {
    const errors: Array<FormValidationError<AddFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }
    if (fields.Email.length < 3) {
        errors.push({ fieldName: "Email", code: "EmailTooShort" });
    }
    if (fields.Company.length < 3) {
        errors.push({ fieldName: "Company", code: "CompanyTooShort" });
    }
    return errors;
};

class Add extends BaseComponent<RouteComponentProps, AddState> {
    state: AddState = {
        fields: {
            Name: "",
            Company: "",
            Email: "",
            SalesmanId: ""
        },
        fieldErrors: [],
        datasource: {
            ClientAdd: DataSourceStateIdle,
            SalesmanList: DataSourceStateIdle
        }
    };

    private dsClientAdd = ds(Endpoint.Client.PostClientAdd, this, "ClientAdd", () => this.context);

    private dsSalesmanList = ds(Endpoint.Salesman.GetSalesmanList, this, "SalesmanList", () => this.context);

    private form = new Form<AddFormFields>(this, addFormValidate, (code) => {
        switch (code) {
            case "NameTooShort":
                return "Nazwa za krótka";
            case "EmailTooShort":
                return "Email za krótki";
            case "TempPasswordTooShort":
                return "Hasło za krótkie";
            case "CompanyTooShort":
                return "Nazwa Firmy za krótka";
            default:
                return code;
        }
    });

    componentDidMount() {
        this.getSalesmanList();
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link to={ROUTER_HOME.Client.List} className="button clear no-left-padding">
                        <span className="button__icon">arrow_back_ios</span>wróć do listy Klientów
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Dodaj nowego klienta</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa Klienta" />
                        <FormTextField config={this.form.getFieldConfig("Email")} label="Adres email" />
                        <FormTextField config={this.form.getFieldConfig("Company")} label="Nazwa Firmy" />
                        <FormSelect
                            config={this.form.getFieldConfig("SalesmanId")}
                            label="wybierz Handlowca"
                            options={this.salesmanList.map((c) => ({ value: c.SalesmanId.toString(), label: c.Name }))}
                        />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get salesmanList() {
        const dsSalesmanListData = this.dsSalesmanList.dataSourceStorage;
        if (dsSalesmanListData.state === "completed") {
            return dsSalesmanListData.response.salesmans;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsClientAdd.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsClientAdd.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsClientAdd.error === "string" ? this.dsClientAdd.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsClientAdd.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsSalesmanList.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsSalesmanList.state === "error") {
            return {
                show: true,
                title: "nie wczytano listy handlowców",
                description: typeof this.dsSalesmanList.error === "string" ? this.dsSalesmanList.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getSalesmanList()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getSalesmanList() {
        await this.dsSalesmanList.request({});
        const dsSalesmanListData = this.dsSalesmanList.dataSourceStorage;
        if (dsSalesmanListData.state === "completed" && dsSalesmanListData.response.salesmans.length > 0) {
            this.form.setFieldValue("SalesmanId", () => dsSalesmanListData.response.salesmans[0].SalesmanId.toString());
        }
    }

    private async submit() {
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsClientAdd.request({
                data: {
                    Name: this.state.fields.Name,
                    Email: this.state.fields.Email,
                    Company: this.state.fields.Company,
                    SalesmanId: parseInt(this.state.fields.SalesmanId)
                }
            });
            const dsAdd = this.dsClientAdd.dataSourceStorage;
            if (dsAdd.state === "completed") {
                this.props.history.push({ pathname: ROUTER_HOME.Client.Detials.About, search: `ClientId=${dsAdd.response.ClientId}` });
            }
        }
    }
}

export default withRouter(Add);
