import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ds } from "../../DataSource";
import { ROUTER_HOME } from "../home/Router";
import { FormatFloat } from "@schneiderpp/utils-generic";


interface ClientListState {
    datasource: {
        List: ParentStateDatasource<typeof Endpoint.Client.GetClientList>;
    };
}

class List extends BaseComponent<RouteComponentProps, ClientListState> {
    state: ClientListState = {
        datasource: {
            List: DataSourceStateIdle
        }
    };

    private dsList = ds(Endpoint.Client.GetClientList, this, "List", () => this.context);

    componentDidMount() {
        this.getList();
    }

    render() {
        return (
            <div className="page">
                <div className="page__header">Lista Klientów</div>
                <div className="page-buttons">
                    <Link to={ROUTER_HOME.Client.Add} className="button small">
                        Dodaj Klienta
                    </Link>
                </div>
                <div className="page-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-2 t-left">Imię i Nazwisko</th>
                                <th className="w-3 t-center">Email</th>
                                <th className="w-3 t-center">Firma</th>
                                <th className="w-2">Handlowiec</th>
                                <th className="w-2 t-right">Stan Konta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.clientList.map((client) => (
                                <tr
                                    className="clickable"
                                    key={client.ClientId}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: ROUTER_HOME.Client.Detials.About,
                                            search: `ClientId=${client.ClientId}`
                                        })
                                    }
                                >
                                    <td className="t-left">{client.Name}</td>
                                    <td className="t-center">{client.Email}</td>
                                    <td className="t-center">{client.Company}</td>
                                    <td>{client.Salesman.Name}</td>
                                    <td className="t-right">{FormatFloat(client.Balance)} ZŁ</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get clientList() {
        const dsListData = this.dsList.dataSourceStorage;
        if (dsListData.state === "completed") {
            return dsListData.response.clients;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsList.state === "pending") {
            return {
                show: true,
                title: "Ładowanie...."
            };
        }
        if (this.dsList.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsList.error === "string" ? this.dsList.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getList()} className="button light">
                            Spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getList() {
        this.dsList.request({});
    }
}

export default withRouter(List);
