import React from "react";
import List from "./List";
import Details from "./Details";
import Add from "./Add";
import Delete from "./Delete";
import Edit from "./Edit";
import { Switch, Route, Redirect } from "react-router-dom";
import AddSize from "./AddSize";
import EditSize from "./EditSize";
import DeleteSize from "./DeleteSize";

const ROUTER_PRODUCTS_BASE = "/Products";

export const ROUTER_PRODUCTS = {
    Base: ROUTER_PRODUCTS_BASE,
    List: ROUTER_PRODUCTS_BASE + "/List",
    Details: ROUTER_PRODUCTS_BASE + "/Details",
    Add: ROUTER_PRODUCTS_BASE + "/Add",
    Edit: ROUTER_PRODUCTS_BASE + "/Edit",
    Delete: ROUTER_PRODUCTS_BASE + "/Delete",
    SizeAdd: ROUTER_PRODUCTS_BASE + "/SizeAdd",
    SizeEdit: ROUTER_PRODUCTS_BASE + "/SizeEdit",
    SizeDelete: ROUTER_PRODUCTS_BASE + "/SizeDelete"
};

const ProductsRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_PRODUCTS.List} component={List} />
            <Route path={ROUTER_PRODUCTS.Details} component={Details} />
            <Route path={ROUTER_PRODUCTS.Add} component={Add} />
            <Route path={ROUTER_PRODUCTS.Edit} component={Edit} />
            <Route path={ROUTER_PRODUCTS.Delete} component={Delete} />
            <Route path={ROUTER_PRODUCTS.SizeAdd} component={AddSize} />
            <Route path={ROUTER_PRODUCTS.SizeEdit} component={EditSize} />
            <Route path={ROUTER_PRODUCTS.SizeDelete} component={DeleteSize} />
            <Redirect path="*" to={ROUTER_PRODUCTS.List} />
        </Switch>
    );
};

export default ProductsRouter;
