import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ds } from "../../DataSource";
import { ROUTER_HOME } from "../home/Router";
import moment from "moment";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface OrderListState {
    datasource: {
        List: ParentStateDatasource<typeof Endpoint.Order.GetOrderList>;
    };
}

class List extends BaseComponent<RouteComponentProps, OrderListState> {
    state: OrderListState = {
        datasource: {
            List: DataSourceStateIdle
        }
    };

    private dsList = ds(Endpoint.Order.GetOrderList, this, "List", () => this.context);

    componentDidMount() {
        this.getList();
    }

    render() {
        return (
            <div className="page">
                <div className="page__header">Lista Zamówień Nagród</div>
                <div className="page-buttons">
                    <Link to={ROUTER_HOME.Orders.Add} className="button small">
                        Dodaj zamówienie nagród
                    </Link>
                </div>
                <div className="page-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-1 t-left">Id Zamówienia</th>
                                <th className="w-1">Klient</th>
                                <th className="w-2">Status</th>
                                <th className="w-3">Data Zamówienia</th>
                                <th className="w-3">Data Utworzenia</th>
                                <th className="w-1 t-right">Wartość</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.orderList.map((orders) => (
                                <tr
                                    className="clickable"
                                    key={orders.OrderId}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: ROUTER_HOME.Orders.Details,
                                            search: `OrderId=${orders.OrderId}`
                                        })
                                    }
                                >
                                    <td className="t-left">{orders.OrderId}</td>
                                    <td className="">{orders.Client.Name}</td>
                                    <td>{orders.Status.Name}</td>
                                    <td>{moment(orders.OrderDate).format("YYYY-MM-DD")}</td>
                                    <td>{moment(orders.CreatedAt).format("YYYY-MM-DD")}</td>
                                    <td className="t-right">{FormatFloat(orders.Value)} ZŁ</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get orderList() {
        const dsListData = this.dsList.dataSourceStorage;
        if (dsListData.state === "completed") {
            return dsListData.response.orders;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsList.state === "pending" || this.dsList.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsList.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsList.error === "string" ? this.dsList.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getList()} className="button light">
                            Spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getList() {
        this.dsList.request({});
    }
}

export default withRouter(List);
