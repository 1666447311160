import React from "react";

import { BaseComponent } from "../../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ds } from "../../../DataSource";
import { ROUTER_HOME } from "../../home/Router";
import moment from "moment";

interface OrderListState {
    datasource: {
        OrderList: ParentStateDatasource<typeof Endpoint.Client.GetClientAgreementList>;
    };
}

interface OrderListProps {
    clientId: number;
}

class AgreementList extends BaseComponent<RouteComponentProps & OrderListProps, OrderListState> {
    state: OrderListState = {
        datasource: {
            OrderList: DataSourceStateIdle
        }
    };

    private dsOrderList = ds(Endpoint.Client.GetClientAgreementList, this, "OrderList", () => this.context);

    componentDidMount() {
        this.getOrderList();
    }

    render() {
        return (
            <div className="page-tab-content">
                <div className="page-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-6 t-left">Nazwa</th>
                                <th className="w-2">Aktywna</th>
                                <th className="w-2">Wymagana</th>
                                <th className="w-2">Zaakceptowana</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.orderList.map(agreement => (
                                <tr
                                    className="clickable"
                                    key={agreement.AgreementId}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: ROUTER_HOME.Agreement.Details,
                                            search: `AgreementId=${agreement.AgreementId}&ClientId=${this.props.clientId}`
                                        })
                                    }
                                >
                                    <td className="t-left">{agreement.Name}</td>
                                    <td className="">{agreement.IsActive ? "TAK" : "NIE"}</td>
                                    <td className="">{agreement.IsRequired ? "TAK" : "NIE"}</td>
                                    <td className="">{agreement.IsChecked ? "TAK" : "NIE"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get orderList() {
        const dsOrderListData = this.dsOrderList.dataSourceStorage;
        if (dsOrderListData.state === "completed") {
            return dsOrderListData.response.Agreements;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsOrderList.state === "pending" || this.dsOrderList.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsOrderList.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsOrderList.error === "string" ? this.dsOrderList.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getOrderList()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getOrderList() {
        await this.dsOrderList.request({
            params: {
                ClientId: this.props.clientId
            }
        });
    }
}

export default withRouter(AgreementList);
