import React from "react";
import List from "./List";
import Add from "./Add";
import Details from "./Details";
import Delete from "./Delete";
import ChangeSupplyStatus from "./ChangeSupplyStatus";
import ChangeSupplyElements from "./ChangeSupplyElements";
import { Route, Switch, Redirect } from "react-router-dom";

const ROUTER_SUPPLIES_BASE = "/Supplies";

export const ROUTER_SUPPLIES = {
    Base: ROUTER_SUPPLIES_BASE,
    List: ROUTER_SUPPLIES_BASE + "/List",
    Details: ROUTER_SUPPLIES_BASE + "/Details",
    ChangeSupplyStatus: ROUTER_SUPPLIES_BASE + "/ChangeSupplyStatus",
    ChangeSupplyElements: ROUTER_SUPPLIES_BASE + "/ChangeSupplyElements",
    Add: ROUTER_SUPPLIES_BASE + "/Add",
    Delete: ROUTER_SUPPLIES_BASE + "/Delete"
};

const SuppliesRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_SUPPLIES.List} component={List} />
            <Route path={ROUTER_SUPPLIES.Details} component={Details} />
            <Route path={ROUTER_SUPPLIES.ChangeSupplyStatus} component={ChangeSupplyStatus} />
            <Route path={ROUTER_SUPPLIES.ChangeSupplyElements} component={ChangeSupplyElements} />
            <Route path={ROUTER_SUPPLIES.Add} component={Add} />
            <Route path={ROUTER_SUPPLIES.Delete} component={Delete} />
            <Redirect path="*" to={ROUTER_SUPPLIES.List} />
        </Switch>
    );
};

export default SuppliesRouter;
