import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { GetURLParams } from "@schneiderpp/utils-generic";

interface EditFormFields {
    Name: string;
    InitialStock: string;
}

interface EditState {
    providedProductSizeId: number | undefined;
    fields: EditFormFields;
    fieldErrors: FormValidationError<EditFormFields>[];
    datasource: {
        Edit: ParentStateDatasource<typeof Endpoint.Product.PostProductSizeEdit>;
        Details: ParentStateDatasource<typeof Endpoint.Product.GetProductSizeDetails>;
    };
}

const editFormValidate: FormValidationFunction<EditFormFields> = async fields => {
    const errors: Array<FormValidationError<EditFormFields>> = [];
    if (fields.Name.length < 1) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }
    if (!Number.isInteger(parseInt(fields.InitialStock))) {
        errors.push({ fieldName: "InitialStock", code: "MustBeNumber" });
    }
    if (parseInt(fields.InitialStock) <= 0) {
        errors.push({ fieldName: "InitialStock", code: "MustBeHigher" });
    }
    return errors;
};

class EditSize extends BaseComponent<RouteComponentProps, EditState> {
    state: EditState = {
        providedProductSizeId: undefined,
        fields: {
            Name: "",
            InitialStock: ""
        },
        fieldErrors: [],
        datasource: {
            Edit: DataSourceStateIdle,
            Details: DataSourceStateIdle
        }
    };

    private dsEdit = ds(Endpoint.Product.PostProductSizeEdit, this, "Edit", () => this.context);

    private dsDetails = ds(Endpoint.Product.GetProductSizeDetails, this, "Details", () => this.context);

    private form = new Form<EditFormFields>(this, editFormValidate, code => {
        switch (code) {
            case "MustBeNumber":
                return "Musi być numerem";
            case "MustBeHigher":
                return "Musi być większe niz 0";
            case "NameTooShort":
                return "Nazwa za krótka";
            default:
                return code;
        }
    });

    componentDidMount() {
        const paramProductSizeId = GetURLParams("ProductSizeId", "number");

        if (!!paramProductSizeId) {
            this.setState({ providedProductSizeId: paramProductSizeId }, () => this.getDetails());
        }
    }

    render() {
        if (!this.state.providedProductSizeId) {
            return (
                <div className="page">
                    <div className="page__header">Nie odnaleziono ProductSizeId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link
                            to={{ pathname: ROUTER_HOME.Products.Details, search: `ProductId=${dsDetailsData.response.ProductId}` }}
                            className="button clear no-left-padding"
                        >
                            <span className="button__icon">arrow_back_ios</span> wróć do szczegółów produktu
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">Edytuj rozmiar produktu</div>
                        <div className="page-form">
                            <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa" />
                            <FormTextField
                                config={this.form.getFieldConfig("InitialStock")}
                                label="Początkowy stan magazynowy"
                                onlyInt={true}
                            />
                            <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                                zatwierdź
                            </button>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle" || this.dsEdit.state === "pending") {
            return {
                show: true,
                title: "Ładowane..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getDetails()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsEdit.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsEdit.error === "string" ? this.dsEdit.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsEdit.resetState()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getDetails() {
        if (!this.state.providedProductSizeId) {
            return;
        }
        await this.dsDetails.request({
            params: { ProductSizeId: this.state.providedProductSizeId }
        });
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            const response = dsDetailsData.response;
            this.setState(() => ({
                fields: {
                    Name: response.Name,
                    InitialStock: response.InitialStock.toString()
                }
            }));
        }
    }

    private async submit() {
        if (!this.state.providedProductSizeId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsEdit.request({
                data: {
                    Name: this.state.fields.Name,
                    InitialStock: parseInt(this.state.fields.InitialStock),
                    ProductSizeId: this.state.providedProductSizeId
                }
            });
            if (this.dsEdit.dataSourceStorage.state === "completed") {
                const dsDetails = this.dsDetails.dataSourceStorage;
                if (dsDetails.state === "completed") {
                    this.props.history.push({
                        pathname: ROUTER_HOME.Products.Details,
                        search: `ProductId=${dsDetails.response.ProductId}`
                    });
                }
            }
        }
    }
}

export default withRouter(EditSize);
