import React from "react";

import { BaseComponent } from "../../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { EndpointDefinitionGetResponse } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ROUTER_HOME } from "../../home/Router";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface Props {
    Details: EndpointDefinitionGetResponse<typeof Endpoint.Client.GetClientDetails>;
}

const About: React.FC<Props> = ({ Details }) => {
    return (
        <>
            <div className="page-buttons">
                <Link to={{ pathname: ROUTER_HOME.Client.Edit, search: `ClientId=${Details.ClientId}` }} className="button small">
                    Edytuj Klienta
                </Link>
            </div>
            <div className="page-tab-content">
                <div className="page__column-view">
                    <div className="page__column">
                        <div className="page-details">
                            <div className="page-details__label">Imię i nazwisko</div>
                            <div className="page-details__value">{Details.Name}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Email Klienta</div>
                            <div className="page-details__value">{Details.Email}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Nazwa Firmy</div>
                            <div className="page-details__value">{Details.Company}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Nazwa Handlowcy</div>
                            <div className="page-details__value">
                                {Details.Salesman.Name}
                                <Link
                                    to={{
                                        pathname: ROUTER_HOME.Salesman.Details.About,
                                        search: `SalesmanId=${Details.Salesman.SalesmanId}`
                                    }}
                                    className="page-details__value-icon"
                                >
                                    launch
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="page__column">
                        <div className="page-details">
                            <div className="page-details__label">Stan Konta</div>
                            <div className="page-details__value">{FormatFloat(Details.Balance)} ZŁ</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Adres dostawy</div>
                            <div className="page-details__value">{Details.Street}</div>
                            <div className="page-details__value">
                                {Details.City}, {Details.PostalCode}
                            </div>
                            <div className="page-details__value">{Details.AddressComment}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Numer telefonu</div>
                            <div className="page-details__value">{Details.Telephone}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
