import React from "react";
import { ds } from "../../DataSource";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ROUTER_HOME } from "../home/Router";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import moment from "moment";
import { FormatFloat } from "@schneiderpp/utils-generic";


interface DeleteState {
    providedOrderId: number | undefined;
    backToClient: boolean;
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Order.GetOrderDetails>;
        Delete: ParentStateDatasource<typeof Endpoint.Order.PostOrderDelete>;
    };
}

class OrderDelete extends BaseComponent<RouteComponentProps, DeleteState> {
    state: DeleteState = {
        providedOrderId: undefined,
        backToClient: false,
        datasource: {
            Details: DataSourceStateIdle,
            Delete: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Order.GetOrderDetails, this, "Details", () => this.context);

    private dsDelete = ds(Endpoint.Order.PostOrderDelete, this, "Delete", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const backToClient = urlParams.get("BackToClient");
        if (!!backToClient && backToClient === "true") {
            this.setState({ backToClient: true });
        }
        const OrderId = urlParams.get("OrderId");
        if (!OrderId) {
            return;
        }
        const OrderIdInt = parseInt(OrderId);
        if (!Number.isInteger(OrderIdInt)) {
            return;
        }
        this.setState({ providedOrderId: OrderIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedOrderId) {
            return (
                <div className="page">
                    <div className="page__header">Nie odnaleziono OrderId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link
                            to={{
                                pathname: ROUTER_HOME.Orders.Details,
                                search: `OrderId=${this.state.providedOrderId}${this.state.backToClient ? "&BackToClient=true" : ""}`
                            }}
                            className="button clear no-left-padding"
                        >
                            <span className="button__icon">arrow_back_ios</span> wróć do Detali zamówienia nagród
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">Usuń zamówienie nagród</div>
                        <div className="page-details">
                            <div className="page-details__label">Data zamówienia nagród</div>
                            <div className="page-details__value">{moment(dsDetailsData.response.OrderDate).format("YYYY-MM-DD")}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Wartość</div>
                            <div className="page-details__value">{FormatFloat(dsDetailsData.response.Value)} ZŁ</div>
                        </div>
                        <div className="page-form">
                            <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                                usuń
                            </button>
                        </div>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle" || this.dsDelete.state === "pending") {
            return {
                show: true,
                title: "Ładowane..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getDetails()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsDelete.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDelete.error === "string" ? this.dsDelete.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsDelete.resetState()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async submit() {
        if (!this.state.providedOrderId) {
            return;
        }
        await this.dsDelete.request({
            data: {
                OrderId: this.state.providedOrderId
            }
        });
        if (this.dsDelete.dataSourceStorage.state === "completed") {
            this.props.history.push(
                this.state.backToClient
                    ? { pathname: ROUTER_HOME.Client.Detials.OrderList, search: `ClientId=${this.dsDetails.response.Client.ClientId}` }
                    : { pathname: ROUTER_HOME.Orders.List }
            );
        }
    }

    private async getDetails() {
        if (!this.state.providedOrderId) {
            return;
        }
        await this.dsDetails.request({
            params: { OrderId: this.state.providedOrderId }
        });
    }
}

export default withRouter(OrderDelete);
