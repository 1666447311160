import React from "react";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import SetNewPassword from "./SetNewPassword";
import { Route, Switch, Redirect } from "react-router-dom";
import SalesmanDetails, { ROUTER_SALESMAN_DETAILS } from "./details/Router";

const ROUTER_SALESMAN_BASE = "/Salesman";

export const ROUTER_SALESMAN = {
    Base: ROUTER_SALESMAN_BASE,
    List: ROUTER_SALESMAN_BASE + "/List",
    Details: ROUTER_SALESMAN_DETAILS,
    Add: ROUTER_SALESMAN_BASE + "/Add",
    Edit: ROUTER_SALESMAN_BASE + "/Edit",
    SetNewPassword: ROUTER_SALESMAN_BASE + "/SetNewPassword"
};

const SalesmanRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_SALESMAN.Details.Base} component={SalesmanDetails} />
            <Route path={ROUTER_SALESMAN.List} component={List} />
            <Route path={ROUTER_SALESMAN.Add} component={Add} />
            <Route path={ROUTER_SALESMAN.Edit} component={Edit} />
            <Route path={ROUTER_SALESMAN.SetNewPassword} component={SetNewPassword} />
            <Redirect path="*" to={ROUTER_SALESMAN.List} />
        </Switch>
    );
};

export default SalesmanRouter;
