import React from "react";
import AgreementList from "./List";
import Details from "./Details";
import AgreementAdd from "./Add";
import Delete from "./Delete";
import Edit from "./Edit";
import { Switch, Route, Redirect } from "react-router-dom";

const ROUTER_AGREEMENTS_BASE = "/Agreement"

export const ROUTER_AGREEMENTS = {
    Base: ROUTER_AGREEMENTS_BASE,
    List: ROUTER_AGREEMENTS_BASE + "/List",
    Details: ROUTER_AGREEMENTS_BASE + "/Details",
    Add: ROUTER_AGREEMENTS_BASE + "/Add",
    Edit: ROUTER_AGREEMENTS_BASE + "/Edit",
    Delete: ROUTER_AGREEMENTS_BASE + "/Delete"
}

const AgreementRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_AGREEMENTS.List} component={AgreementList} />
            <Route path={ROUTER_AGREEMENTS.Details} component={Details} />
            <Route path={ROUTER_AGREEMENTS.Add} component={AgreementAdd} />
            <Route path={ROUTER_AGREEMENTS.Edit} component={Edit} />
            <Route path={ROUTER_AGREEMENTS.Delete} component={Delete} />
            <Redirect path="*" to={ROUTER_AGREEMENTS.List} />
        </Switch>
    )
}

export default AgreementRouter;