import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { GetURLParams } from "@schneiderpp/utils-generic";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface AddFormFields {
    Name: string;
    InitialStock: string;
}

interface AddState {
    providedProductId: number | undefined;
    fields: AddFormFields;
    fieldErrors: FormValidationError<AddFormFields>[];
    datasource: {
        Add: ParentStateDatasource<typeof Endpoint.Product.PostProductSizeAdd>;
    };
}

const addFormValidate: FormValidationFunction<AddFormFields> = async fields => {
    const errors: Array<FormValidationError<AddFormFields>> = [];
    if (fields.Name.length < 1) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }
    if (!Number.isInteger(parseInt(fields.InitialStock))) {
        errors.push({ fieldName: "InitialStock", code: "MustBeNumber" });
    }
    if (parseInt(fields.InitialStock) <= 0) {
        errors.push({ fieldName: "InitialStock", code: "MustBeHigher" });
    }
    return errors;
};

class AddSize extends BaseComponent<RouteComponentProps, AddState> {
    state: AddState = {
        providedProductId: undefined,
        fields: {
            Name: "",
            InitialStock: ""
        },
        fieldErrors: [],
        datasource: {
            Add: DataSourceStateIdle
        }
    };

    private dsAdd = ds(Endpoint.Product.PostProductSizeAdd, this, "Add", () => this.context);

    private form = new Form<AddFormFields>(this, addFormValidate, code => {
        switch (code) {
            case "MustBeNumber":
                return "Musi być liczbą";
            case "MustBeHigher":
                return "Musi być większe niz 0";
            case "NameTooShort":
                return "Nazwa za krótka";
            default:
                return code;
        }
    });

    componentDidMount() {
        const ParamProductId = GetURLParams("ProductId", "number");
        if (!!ParamProductId) {
            this.setState({ providedProductId: ParamProductId });
        } else {
            this.props.history.push({ pathname: ROUTER_HOME.Products.List });
        }
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link
                        to={{ pathname: ROUTER_HOME.Products.Details, search: `ProductId=${this.state.providedProductId}` }}
                        className="button clear no-left-padding"
                    >
                        <span className="button__icon">arrow_back_ios</span> wróć do produktu
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Dodaj nowy rozmiar produktu</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa" />
                        <FormTextField
                            config={this.form.getFieldConfig("InitialStock")}
                            onlyInt={true}
                            label="Początkowy stan magazynowy"
                        />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsAdd.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsAdd.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsAdd.error === "string" ? this.dsAdd.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsAdd.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async submit() {
        if (!this.state.providedProductId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsAdd.request({
                data: {
                    ProductId: this.state.providedProductId,
                    Name: this.state.fields.Name,
                    InitalStock: parseInt(this.state.fields.InitialStock)
                }
            });
            if (this.dsAdd.dataSourceStorage.state === "completed") {
                this.props.history.push({ pathname: ROUTER_HOME.Products.Details, search: `ProductId=${this.state.providedProductId}` });
            }
        }
    }
}

export default withRouter(AddSize);
