import React from "react";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ds } from "../../DataSource";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface DetailsState {
    providedProductId: number | undefined;
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Product.GetProductDetails>;
    };
}

class ProductDetails extends BaseComponent<RouteComponentProps, DetailsState> {
    state: DetailsState = {
        providedProductId: undefined,
        datasource: {
            Details: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Product.GetProductDetails, this, "Details", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const ProductId = urlParams.get("ProductId");
        if (!ProductId) {
            return;
        }
        const ProductIdInt = parseInt(ProductId);
        if (!Number.isInteger(ProductIdInt)) {
            return;
        }
        this.setState({ providedProductId: ProductIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedProductId) {
            return (
                <div className="page">
                    <div className="page__header">Nie Wczytano ProductId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link to={ROUTER_HOME.Products.List} className="button clear no-left-padding">
                            <span className="button__icon">arrow_back_ios</span>wróć do listy produktów
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__column-view">
                            <div className="page__column">
                                <div className="page__header">Detale produktu</div>
                                {!dsDetailsData.response.IsDeleted ? (
                                    <div className="page-buttons">
                                        <Link
                                            to={{
                                                pathname: ROUTER_HOME.Products.Edit,
                                                search: `ProductId=${this.state.providedProductId}`
                                            }}
                                            className="button small"
                                        >
                                            Edytuj Produkt
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: ROUTER_HOME.Products.Delete,
                                                search: `ProductId=${this.state.providedProductId}`
                                            }}
                                            className="button small"
                                        >
                                            Usuń Produkt
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="page__header is-warning">Produkt usunięty</div>
                                )}
                                <div className="page-tab-content">
                                    <div className="page-details">
                                        <div className="page-details__label">Nazwa Produktu</div>
                                        <div className="page-details__value">{dsDetailsData.response.Name}</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Cena</div>
                                        <div className="page-details__value">{FormatFloat(dsDetailsData.response.Value)} ZŁ</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Opis Produktu</div>
                                        <div className="page-details__value">{dsDetailsData.response.Description}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="page__column">
                                <div className="page__header">Rozmiary</div>
                                <div className="page-buttons">
                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Products.SizeAdd,
                                            search: `ProductId=${dsDetailsData.response.ProductId}`
                                        }}
                                        className="button small"
                                    >
                                        Dodaj rozmiar
                                    </Link>
                                </div>
                                <div className="page-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="w-2 t-left">Rozmiar</th>
                                                <th className="w-3">Stan Początkowy</th>
                                                <th className="w-2">Zamówionych</th>
                                                <th className="w-3">Stan aktualny</th>
                                                <th className="w-1"></th>
                                                <th className="w-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.dsDetails.response.Sizes.map((size) => (
                                                <tr className="clickable" key={size.ProductSizeId}>
                                                    <td className="t-left">{size.Name}</td>
                                                    <td>{size.InitialStock}</td>
                                                    <td>{size.CurrentOrders}</td>
                                                    <td>{size.InitialStock - size.CurrentOrders}</td>
                                                    <td>
                                                        <button
                                                            className="button small"
                                                            onClick={() =>
                                                                this.props.history.push({
                                                                    pathname: ROUTER_HOME.Products.SizeEdit,
                                                                    search: `ProductSizeId=${size.ProductSizeId}`
                                                                })
                                                            }
                                                        >
                                                            Edytuj
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="button small"
                                                            onClick={() =>
                                                                this.props.history.push({
                                                                    pathname: ROUTER_HOME.Products.SizeDelete,
                                                                    search: `ProductSizeId=${size.ProductSizeId}`
                                                                })
                                                            }
                                                        >
                                                            Usuń
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button small" onClick={() => this.getDetails()}></button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getDetails() {
        if (!this.state.providedProductId) {
            return;
        }
        this.dsDetails.request({
            params: { ProductId: this.state.providedProductId }
        });
    }
}

export default withRouter(ProductDetails);
