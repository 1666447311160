import React from "react";
import { ds } from "../../DataSource";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ROUTER_HOME } from "../home/Router";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface DeleteState {
    providedAgreementId: number | undefined;
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Agreement.GetAgreementDetails>;
        Delete: ParentStateDatasource<typeof Endpoint.Product.PostProductDelete>;
    };
}

class AgreementDelete extends BaseComponent<RouteComponentProps, DeleteState> {
    state: DeleteState = {
        providedAgreementId: undefined,
        datasource: {
            Details: DataSourceStateIdle,
            Delete: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Agreement.GetAgreementDetails, this, "Details", () => this.context);

    private dsDelete = ds(Endpoint.Agreement.PostAgreementDelete, this, "Delete", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const AgreementId = urlParams.get("AgreementId");
        if (!AgreementId) {
            return;
        }
        const AgreementIdInt = parseInt(AgreementId);
        if (!Number.isInteger(AgreementIdInt)) {
            return;
        }
        this.setState({ providedAgreementId: AgreementIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedAgreementId) {
            return (
                <div className="page">
                    <div className="page__header">Nie odnaleziono AgreementId</div>
                </div>
            );
        }
        const dsDetails = this.dsDetails.dataSourceStorage;
        if (dsDetails.state === "completed") {
            return (
                <>
                    <div className="header">
                        {" "}
                        <Link
                            to={{ pathname: ROUTER_HOME.Agreement.Details, search: `AgreementId=${this.state.providedAgreementId}` }}
                            className="button clear no-left-padding"
                        >
                            <span className="button__icon">arrow_back_ios</span> wróć do Detali zgody
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">Usuń zgodę</div>
                        <div className="page-details">
                            <div className="page-details__label">Nazwa</div>
                            <div className="page-details__value">{dsDetails.response.Name}</div>
                        </div>
                        <div className="page-form">
                            <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                                usuń
                            </button>
                        </div>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle" || this.dsDelete.state === "pending") {
            return {
                show: true,
                title: "Ładowane..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getDetails()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsDelete.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsDelete.resetState()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async submit() {
        if (!this.state.providedAgreementId) {
            return;
        }
        await this.dsDelete.request({
            data: {
                AgreementId: this.state.providedAgreementId
            }
        });
        if (this.dsDelete.dataSourceStorage.state === "completed") {
            this.props.history.push({ pathname: ROUTER_HOME.Agreement.List});
        }
    }

    private async getDetails() {
        if (!this.state.providedAgreementId) {
            return;
        }
        await this.dsDetails.request({
            params: { AgreementId: this.state.providedAgreementId }
        });
    }
}

export default withRouter(AgreementDelete);
