import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface EditFormFields {
    Name: string;
    Description: string;
    Value: string;
}

interface EditState {
    providedProductId: number | undefined;
    fields: EditFormFields;
    fieldErrors: FormValidationError<EditFormFields>[];
    datasource: {
        Edit: ParentStateDatasource<typeof Endpoint.Product.PostProductEdit>;
        Details: ParentStateDatasource<typeof Endpoint.Product.GetProductDetails>;
    };
}

const editFormValidate: FormValidationFunction<EditFormFields> = async fields => {
    const errors: Array<FormValidationError<EditFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }
    if (!Number.isInteger(parseInt(fields.Value))) {
        errors.push({ fieldName: "Value", code: "MustBeNumber" });
    }
    if (parseInt(fields.Value) <= 0) {
        errors.push({ fieldName: "Value", code: "MustBeHigher" });
    }
    return errors;
};

class Edit extends BaseComponent<RouteComponentProps, EditState> {
    state: EditState = {
        providedProductId: undefined,
        fields: {
            Name: "",
            Description: "",
            Value: ""
        },
        fieldErrors: [],
        datasource: {
            Edit: DataSourceStateIdle,
            Details: DataSourceStateIdle
        }
    };

    private dsEdit = ds(Endpoint.Product.PostProductEdit, this, "Edit", () => this.context);

    private dsDetails = ds(Endpoint.Product.GetProductDetails, this, "Details", () => this.context);

    private form = new Form<EditFormFields>(this, editFormValidate, code => {
        switch (code) {
            case "MustBeNumber":
                return "Musi być numerem";
            case "MustBeHigher":
                return "Musi być większe niz 0";
            case "NameTooShort":
                return "Nazwa za krótka";
            default:
                return code;
        }
    });

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const ProductId = urlParams.get("ProductId");
        if (!ProductId) {
            return;
        }
        const ProductIdInt = parseInt(ProductId);
        if (!Number.isInteger(ProductIdInt)) {
            return;
        }
        this.setState({ providedProductId: ProductIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedProductId) {
            return (
                <div className="page">
                    <div className="page__header">Nie odnaleziono ProductId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link
                            to={{ pathname: ROUTER_HOME.Products.Details, search: `ProductId=${dsDetailsData.response.ProductId}` }}
                            className="button clear no-left-padding"
                        >
                            <span className="button__icon">arrow_back_ios</span> wróć do Detali produktu
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">Edytuj produkt</div>
                        <div className="page-form">
                            <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa" />
                            <FormTextField config={this.form.getFieldConfig("Value")} label="Cena" />
                            <FormTextField config={this.form.getFieldConfig("Description")} label="Opis" />
                            <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                                zatwierdź
                            </button>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle" || this.dsEdit.state === "pending") {
            return {
                show: true,
                title: "Ładowane..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getDetails()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsEdit.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsEdit.error === "string" ? this.dsEdit.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsEdit.resetState()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getDetails() {
        if (!this.state.providedProductId) {
            return;
        }
        await this.dsDetails.request({
            params: { ProductId: this.state.providedProductId }
        });
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            const response = dsDetailsData.response;
            this.setState(() => ({
                fields: {
                    Name: response.Name,
                    Description: response.Description,
                    Value: response.Value.toString()
                }
            }));
        }
    }

    private async submit() {
        if (!this.state.providedProductId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsEdit.request({
                data: {
                    Name: this.state.fields.Name,
                    Description: this.state.fields.Description,
                    Value: parseInt(this.state.fields.Value),
                    ProductId: this.state.providedProductId
                }
            });
            if (this.dsEdit.dataSourceStorage.state === "completed") {
                this.props.history.push({ pathname: ROUTER_HOME.Products.Details, search: `ProductId=${this.state.providedProductId}` });
            }
        }
    }
}

export default withRouter(Edit);
