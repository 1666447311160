import React from "react";
import List from "./List";
import Details from "./Details";
import ChangeOrderStatus from "./ChangeOrderStatus";
import ChangeOrderProducts from "./ChangeOrderProducts";
import Add from "./Add";
import Delete from "./Delete";
import { Route, Switch, Redirect } from "react-router-dom";

const ROUTER_ORDERS_BASE = "/Orders";

export const ROUTER_ORDERS = {
    Base: ROUTER_ORDERS_BASE,
    List: ROUTER_ORDERS_BASE + "/List",
    Details: ROUTER_ORDERS_BASE + "/Details",
    ChangeOrderStatus: ROUTER_ORDERS_BASE + "/ChangeOrderStatus",
    Add: ROUTER_ORDERS_BASE + "/Add",
    ChangeOrderProducts: ROUTER_ORDERS_BASE + "/ChangeOrderProducts",
    Delete: ROUTER_ORDERS_BASE + "/Delete"
};

const SalesmanRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_ORDERS.List} component={List} />
            <Route path={ROUTER_ORDERS.Details} component={Details} />
            <Route path={ROUTER_ORDERS.ChangeOrderStatus} component={ChangeOrderStatus} />
            <Route path={ROUTER_ORDERS.Add} component={Add} />
            <Route path={ROUTER_ORDERS.ChangeOrderProducts} component={ChangeOrderProducts} />
            <Route path={ROUTER_ORDERS.Delete} component={Delete} />
            <Redirect path="*" to={ROUTER_ORDERS.List} />
        </Switch>
    );
};

export default SalesmanRouter;
