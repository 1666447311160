import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";

import Nav from "./Nav";
import ClientRouter, { ROUTER_CLIENT } from "../client/Router";
import OrdersRouter, { ROUTER_ORDERS } from "../orders/Router";
import SalesmanRouter, { ROUTER_SALESMAN } from "../salesman/Router";
import SuppliesRouter, { ROUTER_SUPPLIES } from "../supplies/Router";
import ProductsRouter, { ROUTER_PRODUCTS } from "../product/Router";
import { Route, Switch } from "react-router-dom";
import AgreementRouter, { ROUTER_AGREEMENTS } from "../agreements/Router";

export const ROUTER_HOME = {
    Client: ROUTER_CLIENT,
    Orders: ROUTER_ORDERS,
    Salesman: ROUTER_SALESMAN,
    Supplies: ROUTER_SUPPLIES,
    Products: ROUTER_PRODUCTS,
    Agreement: ROUTER_AGREEMENTS
};

export default class HomeRouter extends BaseComponent {
    render() {
        return (
            <div className="router-home">
                <Nav />
                <div className="router-home__content">
                    <Switch>
                        <Route path={ROUTER_HOME.Client.Base} component={ClientRouter} />
                        <Route path={ROUTER_HOME.Orders.Base} component={OrdersRouter} />
                        <Route path={ROUTER_HOME.Salesman.Base} component={SalesmanRouter} />
                        <Route path={ROUTER_HOME.Supplies.Base} component={SuppliesRouter} />
                        <Route path={ROUTER_HOME.Products.Base} component={ProductsRouter} />
                        <Route path={ROUTER_HOME.Agreement.Base} component={AgreementRouter} />
                    </Switch>
                </div>
            </div>
        );
    }
}
