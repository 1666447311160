import React from "react";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ds } from "../../DataSource";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";

interface AgreementDetailsState {
    providedAgreementId: number | undefined;
    providedClientId: number | undefined;
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Agreement.GetAgreementDetails>;
    };
}

class AgreementDetails extends BaseComponent<RouteComponentProps, AgreementDetailsState> {
    state: AgreementDetailsState = {
        providedAgreementId: undefined,
        providedClientId: undefined,
        datasource: {
            Details: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Agreement.GetAgreementDetails, this, "Details", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const ClientId = urlParams.get("ClientId");

        if (!!ClientId) {
            const ClientIdInt = parseInt(ClientId);
            if (Number.isInteger(ClientIdInt)) {
                this.setState({ providedClientId: ClientIdInt });
            }
        }

        const ProductId = urlParams.get("AgreementId");
        if (!ProductId) {
            return;
        }
        const AgreementIdInt = parseInt(ProductId);
        if (!Number.isInteger(AgreementIdInt)) {
            return;
        }
        this.setState({ providedAgreementId: AgreementIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedAgreementId) {
            return (
                <div className="page">
                    <div className="page__header">Nie Wczytano AgreementId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        {this.state.providedClientId ? (
                            <Link
                                to={{
                                    pathname: ROUTER_HOME.Client.Detials.AgreementList,
                                    search: `ClientId=${this.state.providedClientId}`
                                }}
                                className="button clear no-left-padding"
                            >
                                <span className="button__icon">arrow_back_ios</span>wróć do listy zgód klienta
                            </Link>
                        ) : (
                            <Link to={ROUTER_HOME.Agreement.List} className="button clear no-left-padding">
                                <span className="button__icon">arrow_back_ios</span>wróć do listy
                            </Link>
                        )}
                    </div>
                    <div className="page">
                        <div className="page__column-view">
                            <div className="page__column">
                                <div className="page__header">Szczegóły zgody: {dsDetailsData.response.Name}</div>
                                <div className="page-buttons">
                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Agreement.Edit,
                                            search: `AgreementId=${this.state.providedAgreementId}`
                                        }}
                                        className="button small"
                                    >
                                        Edytuj Zgodę
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Agreement.Delete,
                                            search: `AgreementId=${this.state.providedAgreementId}`
                                        }}
                                        className="button small"
                                    >
                                        Usuń Zgodę
                                    </Link>
                                </div>

                                <div className="page-tab-content">
                                    <div className="page-details">
                                        <div className="page-details__label">Nazwa Zgody</div>
                                        <div className="page-details__value">{dsDetailsData.response.Name}</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Opis</div>
                                        <div className="page-details__value">{dsDetailsData.response.Description}</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Link do dokumentu</div>
                                        <div className="page-details__value">{dsDetailsData.response.LinkToDocument}</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Aktywna</div>
                                        <div className="page-details__value">{dsDetailsData.response.IsActive ? "TAK" : "NIE"}</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Wymagana</div>
                                        <div className="page-details__value">{dsDetailsData.response.IsRequired ? "TAK" : "NIE"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="page__column">
                                <div className="page__header">Klienci którzy wyrazili zgodę</div>
                                <div className="page-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="w-6">Nazwa</th>
                                                <th className="w-6">Firma</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dsDetailsData.response.Clients.map((client) => (
                                                <tr
                                                    className="clickable"
                                                    key={client.ClientId}
                                                    onClick={() =>
                                                        this.props.history.push({
                                                            pathname: ROUTER_HOME.Client.Detials.About,
                                                            search: `ClientId=${client.ClientId}`
                                                        })
                                                    }
                                                >
                                                    <td className="">{client.Name}</td>
                                                    <td>{client.Company}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                children: (
                    <div className="overlay__children">
                        <button className="button small" onClick={() => this.getDetails()}></button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getDetails() {
        if (!this.state.providedAgreementId) {
            return;
        }
        this.dsDetails.request({
            params: { AgreementId: this.state.providedAgreementId }
        });
    }
}

export default withRouter(AgreementDetails);
