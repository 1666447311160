import React from "react";

import { ds } from "../../DataSource";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { ROUTER_HOME } from "../home/Router";

interface AddFormFields {
    Name: string;
    Email: string;
}

interface AddState {
    fields: AddFormFields;
    fieldErrors: FormValidationError<AddFormFields>[];
    datasource: {
        Add: ParentStateDatasource<typeof Endpoint.Salesman.PostSalesmanAdd>;
    };
}

const addFormValidate: FormValidationFunction<AddFormFields> = async (fields) => {
    const errors: Array<FormValidationError<AddFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "SalesmanNameTooShort" });
    }
    if (fields.Email.length < 3) {
        errors.push({ fieldName: "Email", code: "EmailTooShort" });
    }
    return errors;
};

class Add extends BaseComponent<RouteComponentProps, AddState> {
    state: AddState = {
        fields: {
            Name: "",
            Email: ""
        },
        fieldErrors: [],
        datasource: {
            Add: DataSourceStateIdle
        }
    };

    private dsAdd = ds(Endpoint.Salesman.PostSalesmanAdd, this, "Add", () => this.context);

    private form = new Form<AddFormFields>(this, addFormValidate, (code) => {
        switch (code) {
            case "SalesmanNameTooShort":
                return "Nazwa za krótka";
            case "EmailTooShort":
                return "Email za krótki";
            default:
                return code;
        }
    });

    render() {
        return (
            <>
                <div className="header">
                    <Link to={ROUTER_HOME.Salesman.List} className="button clear no-left-padding">
                        <span className="button__icon">arrow_back_ios</span>wróć do listy Handlowców
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Dodaj Nowego Handlowce</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa" />
                        <FormTextField config={this.form.getFieldConfig("Email")} label="Email" />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            Zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsAdd.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsAdd.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsAdd.error === "string" ? this.dsAdd.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsAdd.resetState()}>Spróbuj Ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async submit() {
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsAdd.request({
                data: {
                    Name: this.state.fields.Name,
                    Email: this.state.fields.Email
                }
            });
            const dsAdd = this.dsAdd.dataSourceStorage;
            if (dsAdd.state === "completed") {
                this.props.history.push({
                    pathname: ROUTER_HOME.Salesman.Details.About,
                    search: `SalesmanId=${dsAdd.response.SalesmanId}`
                });
            }
        }
    }
}

export default withRouter(Add);
