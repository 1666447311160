import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { ds } from "../../DataSource";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";
import moment from "moment";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface DetailsState {
    providedOrderId: number | undefined;
    backToClient: boolean;
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Order.GetOrderDetails>;
    };
}

class OrderDetails extends BaseComponent<RouteComponentProps, DetailsState> {
    state: DetailsState = {
        providedOrderId: undefined,
        backToClient: false,
        datasource: {
            Details: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Order.GetOrderDetails, this, "Details", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const backToClient = urlParams.get("BackToClient");
        if (!!backToClient && backToClient === "true") {
            this.setState({ backToClient: true });
        }
        const OrderId = urlParams.get("OrderId");
        if (!OrderId) {
            return;
        }
        const OrderIdInt = parseInt(OrderId);
        if (!Number.isInteger(OrderIdInt)) {
            return;
        }
        this.setState({ providedOrderId: OrderIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedOrderId) {
            return (
                <div className="page">
                    <div className="page__header">Nie wczytano OrderId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        {this.state.backToClient ? (
                            <Link
                                to={{
                                    pathname: ROUTER_HOME.Client.Detials.OrderList,
                                    search: `ClientId=${this.dsDetails.response.Client.ClientId}`
                                }}
                                className="button clear no-left-padding"
                            >
                                <span className="button__icon">arrow_back_ios</span> wróć do listy zamówień Nagród klienta
                                {this.dsDetails.response.Client.Name}
                            </Link>
                        ) : (
                            <Link to={ROUTER_HOME.Orders.List} className="button clear no-left-padding">
                                <span className="button__icon">arrow_back_ios</span> wróć do listy zamówień Nagród
                            </Link>
                        )}
                    </div>
                    <div className="page">
                        <div className="page__column-view">
                            <div className="page__column">
                                <div className="page__header">
                                    Zamówienie z dnia {moment(dsDetailsData.response.OrderDate).format("YYYY-MM-DD")}
                                </div>
                                <div className="page-buttons">
                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Orders.ChangeOrderStatus,
                                            search: `OrderId=${dsDetailsData.response.OrderId}${
                                                this.state.backToClient ? "&BackToClient=true" : ""
                                            }`
                                        }}
                                        className="button small"
                                    >
                                        Zmień Status zamówienia nagród
                                    </Link>

                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Orders.ChangeOrderProducts,
                                            search: `OrderId=${dsDetailsData.response.OrderId}${
                                                this.state.backToClient ? "&BackToClient=true" : ""
                                            }`
                                        }}
                                        className="button small"
                                    >
                                        Zmień wybrane produkty
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Orders.Delete,
                                            search: `OrderId=${dsDetailsData.response.OrderId}${
                                                this.state.backToClient ? "&BackToClient=true" : ""
                                            }`
                                        }}
                                        className="button small"
                                    >
                                        Usuń zamówienie nagród
                                    </Link>
                                </div>
                                <div className="page-tab-content">
                                    <div className="page-details">
                                        <div className="page-details__label">Klient</div>
                                        <div className="page-details__value">
                                            {dsDetailsData.response.Client.Name}{" "}
                                            <Link
                                                to={{
                                                    pathname: ROUTER_HOME.Client.Detials.About,
                                                    search: `ClientId=${dsDetailsData.response.Client.ClientId}`
                                                }}
                                                className="page-details__value-icon"
                                            >
                                                launch
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Status Zamówienia</div>
                                        <div className="page-details__value">{dsDetailsData.response.Status.Name}</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Data Utworzenia </div>
                                        <div className="page-details__value">
                                            {moment(dsDetailsData.response.CreatedAt).format("YYYY-MM-DD")}
                                        </div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Data Zamówienia</div>
                                        <div className="page-details__value">
                                            {moment(dsDetailsData.response.OrderDate).format("YYYY-MM-DD")}
                                        </div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Wartość Zamówienia</div>
                                        <div className="page-details__value">{FormatFloat(dsDetailsData.response.Value)} ZŁ</div>
                                    </div>
                                </div>
                            </div>
                            <div className="page__column">
                                <div className="page__header">Elementy Zamówienia Nagród</div>
                                <div className="page-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="w-4 t-left">Nazwa</th>
                                                <th className="w-4">Ilość</th>
                                                <th className="w-4 t-right">Wartość</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dsDetailsData.response.Products.map((element) => (
                                                <tr key={element.OrderProductId}>
                                                    <td className="t-left">{element.Product.Name}</td>
                                                    <td>{element.Quantity}</td>
                                                    <td className="t-right">{FormatFloat(element.Value)} ZŁ</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle") {
            return {
                show: true,
                title: "Ładowanie"
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getDetails()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getDetails() {
        if (!this.state.providedOrderId) {
            return;
        }
        this.dsDetails.request({
            params: { OrderId: this.state.providedOrderId }
        });
    }
}

export default withRouter(OrderDetails);
