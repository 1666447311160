import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationFunction, FormValidationError, Form, FormSelect, FormGroup, FormTextField } from "@schneiderpp/utils-forms";
import { ds } from "../../DataSource";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";

interface OrderProduct {
    OrderProductId: string;
    Quantity: string;
}

interface ChangeOrderProductsFormFields {
    Products: OrderProduct[];
}

interface ChangeOrderProductsState {
    providedOrderId: number | undefined;
    backToClient: boolean;
    fields: ChangeOrderProductsFormFields;
    fieldErrors: FormValidationError<ChangeOrderProductsFormFields>[];
    datasource: {
        OrderDetails: ParentStateDatasource<typeof Endpoint.Order.GetOrderDetails>;
        OrderChangeProducts: ParentStateDatasource<typeof Endpoint.Order.PostOrderChangeProducts>;
        ProductList: ParentStateDatasource<typeof Endpoint.Product.GetProductList>;
    };
}

const formValidate: FormValidationFunction<ChangeOrderProductsFormFields> = async fields => {
    const errors: Array<FormValidationError<ChangeOrderProductsFormFields>> = [];

    for (let i = 0; i < fields.Products.length; i++) {
        if (parseInt(fields.Products[i].Quantity) < 1) {
            errors.push({
                fieldName: "Products",
                code: "ElementQuantityMustBePositiveInteger",
                index: i,
                arrayFieldName: "Quantity"
            });
        }
        if (!Number.isInteger(parseInt(fields.Products[i].Quantity))) {
            errors.push({
                fieldName: "Products",
                code: "MustBeNumber",
                index: i,
                arrayFieldName: "Quantity"
            });
        }
    }
    return errors;
};

class ChangeSupplyElements extends BaseComponent<RouteComponentProps, ChangeOrderProductsState> {
    state: ChangeOrderProductsState = {
        providedOrderId: undefined,
        backToClient: false,
        fields: {
            Products: []
        },
        fieldErrors: [],
        datasource: {
            OrderDetails: DataSourceStateIdle,
            OrderChangeProducts: DataSourceStateIdle,
            ProductList: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Order.GetOrderDetails, this, "OrderDetails", () => this.context);

    private dsChangeProducts = ds(Endpoint.Order.PostOrderChangeProducts, this, "OrderChangeProducts", () => this.context);

    private dsProductList = ds(Endpoint.Product.GetProductList, this, "ProductList", () => this.context);

    private form = new Form<ChangeOrderProductsFormFields>(this, formValidate, code => code);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const backToClient = urlParams.get("BackToClient");
        if (!!backToClient && backToClient === "true") {
            this.setState({ backToClient: true });
        }
        const OrderId = urlParams.get("OrderId");
        if (!OrderId) {
            return;
        }
        const OrderIdInt = parseInt(OrderId);
        if (!Number.isInteger(OrderIdInt)) {
            return;
        }
        this.setState({ providedOrderId: OrderIdInt }, () => {
            this.getSupplyDetails();
            this.getProductList();
        });
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link
                        to={{
                            pathname: ROUTER_HOME.Orders.Details,
                            search: `OrderId=${this.state.providedOrderId}${this.state.backToClient ? "&BackToClient=true" : ""}`
                        }}
                        className="button clear no-left-padding"
                    >
                        <span className="button__icon">arrow_back_ios</span>
                        wróć do Detali zamówienia nagród
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Zmień Elementy Zamówienia Nagród</div>
                    <div className="page-form">
                        <FormGroup
                            config={this.form.getFieldConfig("Products")}
                            element={(c, i) => (
                                <div className="page-form__column">
                                    <FormSelect
                                        className="size-1-2"
                                        config={c("OrderProductId")}
                                        label="Produkt"
                                        options={this.getAvailableProductsForSelect(c("OrderProductId").value()).map(c => ({
                                            value: c.ProductSizeId.toString(),
                                            label: c.Name
                                        }))}
                                    />
                                    <FormTextField className="size-1-2" config={c("Quantity")} label="Ilość" onlyInt={true} />
                                    <button onClick={() => this.removeProduct(i)} className="button icon margin-top-32">
                                        delete
                                    </button>
                                </div>
                            )}
                        />
                        {this.availableProducts.length > 0 ? (
                            <button onClick={() => this.addProduct()} className="button margin-bottom-10">
                                + Dodaj element
                            </button>
                        ) : (
                            undefined
                        )}
                        <button className="button align-self-end" onClick={() => this.submit()}>
                            Zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsChangeProducts.state === "pending" || this.dsDetails.state === "idle" || this.dsDetails.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsChangeProducts.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsChangeProducts.error === "string" ? this.dsChangeProducts.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsChangeProducts.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getSupplyDetails()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }

        return {
            show: false
        };
    }

    get availableProducts() {
        return this.productList.filter(
            p => this.state.fields.Products.findIndex(e => parseInt(e.OrderProductId) === p.ProductSizeId) === -1
        );
    }

    private getAvailableProductsForSelect(currentProductId: string) {
        const currentProduct = this.productList.find(e => e.ProductSizeId === parseInt(currentProductId));
        if (!currentProduct) {
            return [...this.availableProducts];
        }
        return [currentProduct, ...this.availableProducts];
    }

    private addProduct() {
        if (this.availableProducts.length < 1) {
            return;
        }
        this.setState(p => ({
            fields: {
                ...p.fields,
                Products: [...p.fields.Products, { OrderProductId: this.availableProducts[0].ProductSizeId.toString(), Quantity: "" }]
            }
        }));
    }

    private removeProduct(index: number) {
        this.setState(p => {
            const newProduct = [...p.fields.Products];
            newProduct.splice(index, 1);

            return {
                fields: {
                    ...p.fields,
                    Products: newProduct
                }
            };
        });
    }

    get productList() {
        const dsProductListData = this.dsProductList.dataSourceStorage;
        if (dsProductListData.state === "completed") {
            return dsProductListData.response.products.reduce(
                (list, product) => [
                    ...list,
                    ...product.Sizes.map(size => ({ ProductSizeId: size.ProductSizeId, Name: `${product.Name} - ${size.Name}` }))
                ],
                [] as { ProductSizeId: number; Name: string }[]
            );
        }
        return [];
    }

    private async getProductList() {
        await this.dsProductList.request({});
    }

    private async getSupplyDetails() {
        if (!this.state.providedOrderId) {
            return;
        }

        await this.dsDetails.request({ params: { OrderId: this.state.providedOrderId } });

        const details = this.dsDetails.dataSourceStorage;

        if (details.state === "completed") {
            this.setState(p => ({
                fields: {
                    ...p.fields,
                    Products: details.response.Products.map(e => ({
                        OrderProductId: e.Product.ProductSizeId.toString(),
                        Quantity: e.Quantity.toString()
                    }))
                }
            }));
        }
    }

    private async submit() {
        if (!this.state.providedOrderId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsChangeProducts.request({
                data: {
                    OrderId: this.state.providedOrderId,
                    Products: this.state.fields.Products.map(e => ({
                        ProductSizeId: parseInt(e.OrderProductId),
                        Quantity: parseInt(e.Quantity)
                    }))
                }
            });
            if (this.dsChangeProducts.dataSourceStorage.state === "completed") {
                this.props.history.push({
                    pathname: ROUTER_HOME.Orders.Details,
                    search: `OrderId=${this.state.providedOrderId}${this.state.backToClient ? "&BackToClient=true" : ""}`
                });
            }
        }
    }
}

export default withRouter(ChangeSupplyElements);
