import React from "react";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import SendInvitation from "./SendInvitation";
import { Route, Switch, Redirect } from "react-router-dom";
import ClientDetails, {ROUTER_CLIENT_DETAILS} from "./details/Router";

const ROUTER_CLIENT_BASE = "/Client";

export const ROUTER_CLIENT = {
    Base: ROUTER_CLIENT_BASE,
    List: ROUTER_CLIENT_BASE + "/List",
    Detials: ROUTER_CLIENT_DETAILS,
    Add: ROUTER_CLIENT_BASE +"/Add",
    AddOrder: ROUTER_CLIENT_BASE + "AddOrder",
    Edit: ROUTER_CLIENT_BASE + "/Edit",
    SendInvitation: ROUTER_CLIENT_BASE + "/SendInvitation"
};

const SalesmanRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_CLIENT.Detials.Base} component={ClientDetails} />
            <Route path={ROUTER_CLIENT.List} component={List} />
            <Route path={ROUTER_CLIENT.Add} component={Add} />
            <Route path={ROUTER_CLIENT.Edit} component={Edit} />
            <Route path={ROUTER_CLIENT.SendInvitation} component={SendInvitation} />
            <Redirect path="*" to={ROUTER_CLIENT.List} />
        </Switch>
    );
};

export default SalesmanRouter;
