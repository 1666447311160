import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { ds } from "../../DataSource";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";
import moment from "moment";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface DetailsState {
    providedSupplyId: number | undefined;
    backToClient: boolean;
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Supply.GetSupplyDetails>;
    };
}

class SupplyDetails extends BaseComponent<RouteComponentProps, DetailsState> {
    state: DetailsState = {
        providedSupplyId: undefined,
        backToClient: false,
        datasource: {
            Details: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Supply.GetSupplyDetails, this, "Details", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const backToClient = urlParams.get("BackToClient");
        if (!!backToClient && backToClient === "true") {
            this.setState({ backToClient: true });
        }
        const SupplyId = urlParams.get("SupplyId");
        if (!SupplyId) {
            return;
        }
        const SupplyIdInt = parseInt(SupplyId);
        if (!Number.isInteger(SupplyIdInt)) {
            return;
        }
        this.setState({ providedSupplyId: SupplyIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedSupplyId) {
            return (
                <div className="page">
                    <div className="page__header">Nie wczytano SupplyId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        {this.state.backToClient ? (
                            <Link
                                to={{
                                    pathname: ROUTER_HOME.Client.Detials.SupplyList,
                                    search: `ClientId=${this.dsDetails.response.Client.ClientId}`
                                }}
                                className="button clear no-left-padding"
                            >
                                <span className="button__icon">arrow_back_ios</span> wróć do listy zatowarowań klienta{" "}
                                {this.dsDetails.response.Client.Name}
                            </Link>
                        ) : (
                            <Link to={ROUTER_HOME.Supplies.List} className="button clear no-left-padding">
                                <span className="button__icon">arrow_back_ios</span> wróć do listy zatowarowań
                            </Link>
                        )}
                    </div>
                    <div className="page">
                        <div className="page__column-view">
                            <div className="page__column">
                                <div className="page__header">
                                    Zatowarowanie z dnia {moment(dsDetailsData.response.SupplyDate).format("YYYY-MM-DD")}
                                </div>
                                <div className="page-buttons">
                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Supplies.ChangeSupplyStatus,
                                            search: `SupplyId=${dsDetailsData.response.SupplyId}${
                                                this.state.backToClient ? "&BackToClient=true" : ""
                                            }`
                                        }}
                                        className="button small"
                                    >
                                        Zmień Status Zatowarowania
                                    </Link>

                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Supplies.ChangeSupplyElements,
                                            search: `SupplyId=${dsDetailsData.response.SupplyId}${
                                                this.state.backToClient ? "&BackToClient=true" : ""
                                            }`
                                        }}
                                        className="button small"
                                    >
                                        Zmień Elementy Zatowarowania
                                    </Link>

                                    <Link
                                        to={{
                                            pathname: ROUTER_HOME.Supplies.Delete,
                                            search: `SupplyId=${dsDetailsData.response.SupplyId}${
                                                this.state.backToClient ? "&BackToClient=true" : ""
                                            }`
                                        }}
                                        className="button small"
                                    >
                                        Usuń zatowarowanie
                                    </Link>
                                </div>
                                <div className="page-tab-content">
                                    <div className="page-details">
                                        <div className="page-details__label">Klient</div>
                                        <div className="page-details__value">
                                            {dsDetailsData.response.Client.Name}{" "}
                                            <Link
                                                to={{
                                                    pathname: ROUTER_HOME.Client.Detials.About,
                                                    search: `ClientId=${dsDetailsData.response.Client.ClientId}`
                                                }}
                                                className="page-details__value-icon"
                                            >
                                                launch
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Status Zatowarowania</div>
                                        <div className="page-details__value">{dsDetailsData.response.Status.Name}</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Data Utworzenia </div>
                                        <div className="page-details__value">
                                            {moment(dsDetailsData.response.CreatedAt).format("YYYY-MM-DD")}
                                        </div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Data Zatowarowania</div>
                                        <div className="page-details__value">
                                            {moment(dsDetailsData.response.SupplyDate).format("YYYY-MM-DD")}
                                        </div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Wartość Zasilenia Konta</div>
                                        <div className="page-details__value">{FormatFloat(dsDetailsData.response.Value)} ZŁ</div>
                                    </div>
                                    <div className="page-details">
                                        <div className="page-details__label">Wartość Zatowarowania</div>
                                        <div className="page-details__value">{FormatFloat(dsDetailsData.response.ValueElements)} ZŁ</div>
                                    </div>
                                </div>
                            </div>
                            <div className="page__column">
                                <div className="page__header">Elementy Zatowarowania</div>
                                <div className="page-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="w-4 t-left">Nazwa</th>
                                                {/* <th className="w-4">Ilość</th> */}
                                                <th className="w-4 t-right">Wartość</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dsDetailsData.response.Elements.map((element) => (
                                                <tr key={element.SupplyElementId}>
                                                    <td className="t-left">{element.Name}</td>
                                                    {/* <td>{element.Quantity}</td> */}
                                                    <td className="t-right">{FormatFloat(element.Value)} ZŁ</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle") {
            return {
                show: true,
                title: "Ładowanie"
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getDetails()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getDetails() {
        if (!this.state.providedSupplyId) {
            return;
        }
        this.dsDetails.request({
            params: { SupplyId: this.state.providedSupplyId }
        });
    }
}

export default withRouter(SupplyDetails);
