import React from "react";

import { ds } from "../../DataSource";
import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ROUTER_HOME } from "../home/Router";

interface PasswordFormFields {
    Password: string;
}

interface PasswordState {
    providedSalesmanId: number | undefined;
    fields: PasswordFormFields;
    fieldErrors: FormValidationError<PasswordFormFields>[];
    datasource: {
        changePassword: ParentStateDatasource<typeof Endpoint.Salesman.PostSalesmanChangePassword>;
    };
}

const formValidate: FormValidationFunction<PasswordFormFields> = async (fields) => {
    const errors: Array<FormValidationError<PasswordFormFields>> = [];
    if (fields.Password.length < 3) {
        errors.push({ fieldName: "Password", code: "PasswordTooShort" });
    }
    return errors;
};

class SetNewPassword extends BaseComponent<RouteComponentProps, PasswordState> {
    state: PasswordState = {
        providedSalesmanId: undefined,
        fields: {
            Password: ""
        },
        fieldErrors: [],
        datasource: {
            changePassword: DataSourceStateIdle
        }
    };

    private dsPassword = ds(Endpoint.Salesman.PostSalesmanChangePassword, this, "changePassword", () => this.context);

    private form = new Form<PasswordFormFields>(this, formValidate, (code) => {
        switch (code) {
            case "PasswordTooShort":
                return "hasło za krótkie";
            default:
                return code;
        }
    });

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const SalesmanId = urlParams.get("SalesmanId");
        if (!SalesmanId) {
            return;
        }
        const SalesManIdInt = parseInt(SalesmanId);
        if (!Number.isInteger(SalesManIdInt)) {
            return;
        }
        this.setState({ providedSalesmanId: SalesManIdInt });
    }

    render() {
        if (!this.state.providedSalesmanId) {
            return (
                <div className="page">
                    <div className="page__header">Nie Odnaleziono SalesmanId</div>
                </div>
            );
        }
        return (
            <>
                <div className="header">
                    <Link
                        to={{ pathname: ROUTER_HOME.Salesman.Details.About, search: `SalesmanId=${this.state.providedSalesmanId}` }}
                        className="button clear no-left-padding"
                    >
                        <span className="button__icon">arrow_back_ios</span> wróć do zakładki: O Handlowcy
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Zmień hasło</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("Password")} label= "Nowe Hasło" />
                        <button className="button" onClick={()=> this.submit()}>
                            zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        if(this.dsPassword.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            }
        }
        if(this.dsPassword.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsPassword.error === "string" ? this.dsPassword.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={()=> this.dsPassword.resetState()}>Spróbuj Ponownie</button>
                    </div>
                )
            }
        }
        return {
            show: false
        }
    }

    private async submit() {
        if (!this.state.providedSalesmanId) {
            return
        }
        const isValid = await this.form.validate();
        if(isValid) {
            await this.dsPassword.request({
                data: {
                    SalesmanId: this.state.providedSalesmanId,
                    TempPassword: this.state.fields.Password
                }
            })
            this.props.history.push(ROUTER_HOME.Salesman.List)
        }
    }
}

export default withRouter(SetNewPassword)