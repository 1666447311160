import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField, FormTextArea, FormCheckbox } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface AddFormFields {
    Name: string;
    Description: string;
    Link: string;
    IsRequired: boolean;
    IsActive: boolean;
}

interface Addstate {
    fields: AddFormFields;
    fieldErrors: FormValidationError<AddFormFields>[];
    datasource: {
        Add: ParentStateDatasource<typeof Endpoint.Agreement.PostAgreementAdd>;
    };
}

const addFormValidate: FormValidationFunction<AddFormFields> = async fields => {
    const errors: Array<FormValidationError<AddFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }
    return errors;
};

class AgreementAdd extends BaseComponent<RouteComponentProps, Addstate> {
    state: Addstate = {
        fields: {
            Name: "",
            Description: "",
            Link: "",
            IsRequired: false,
            IsActive: false
        },
        fieldErrors: [],
        datasource: {
            Add: DataSourceStateIdle
        }
    };

    private dsAdd = ds(Endpoint.Agreement.PostAgreementAdd, this, "Add", () => this.context);

    private form = new Form<AddFormFields>(this, addFormValidate, code => {
        switch (code) {
            case "MustBeNumber":
                return "Musi być numerem";
            case "MustBeHigher":
                return "Musi być większe niz 0";
            case "NameTooShort":
                return "Nazwa za krótka";
            default:
                return code;
        }
    });

    render() {
        return (
            <>
                <div className="header">
                    <Link to={ROUTER_HOME.Agreement.List} className="button clear no-left-padding">
                        <span className="button__icon">arrow_back_ios</span> wróć do listy zgód
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Dodaj nową zgodę</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa" />
                        <FormTextArea config={this.form.getFieldConfig("Description")} label="Opis" />
                        <FormTextField config={this.form.getFieldConfig("Link")} label="Link do dokumentu" />
                        <FormCheckbox config={this.form.getFieldConfig("IsActive")} label="Aktywna" checkboxLabel="Zgoda aktywna" />
                        <FormCheckbox config={this.form.getFieldConfig("IsRequired")} label="Wymagana" checkboxLabel="Zgoda wymagana" />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsAdd.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsAdd.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsAdd.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async submit() {
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsAdd.request({
                data: {
                    Name: this.state.fields.Name,
                    Description: this.state.fields.Description,
                    IsActive: this.state.fields.IsActive,
                    IsRequired: this.state.fields.IsRequired
                }
            });
            if (this.dsAdd.dataSourceStorage.state === "completed") {
                this.props.history.push(ROUTER_HOME.Agreement.List);
            }
        }
    }
}

export default withRouter(AgreementAdd);
